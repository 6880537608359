import React from 'react'
import Layout from '../layouts/layout';
import SEO from '../layouts/seo';
import { graphql } from 'gatsby'
import StaticPageRibbons from '../staticpage/staticpage-ribbons';
import StaticPageHalfHalf from '../staticpage/staticpage-half-half';
import StaticPageFullWidth from '../staticpage/staticpage-full-width';
import { connect } from 'react-redux';

interface props {
  data: any
  browser: string
  transitionStatus: string
}

interface state {
}

class Staticpage extends React.Component<props, state> {
  
  constructor(props) {
    super(props);
    this.state = {
    };

  }

  componentDidMount = () => {
  }

  render () {
    let data = this.props.data;
    let {transitionStatus} = this.props;
    let halfHalfCount = 0;
    
    return (
      <>
        <SEO
          title={data.pageData.staticPageTitle} 
          description={data.pageData.staticPageDescription} 
        />
        <Layout
          data={data}
          class={data.pageData.staticPageSlug}
          pageTitle={data.pageData.staticPageTitle}
          transitionStatus={transitionStatus}
        >
        <StaticPageRibbons
          data={data}
          browser={this.props.browser}
        />
        <div className={`static-container static-page`}>
          <h1 className={`static-page__title`}>{data.pageData.staticPageTitle}</h1>
          {data.pageData.staticPageContent.length > 0 ? 
            <>
              {data.pageData.staticPageContent.map((contentBlock, index) => {
                halfHalfCount++;
                switch (contentBlock.model.name) {
                  case 'Full Width Content':
                    return (
                      <StaticPageFullWidth 
                        data={contentBlock} 
                        key={`content-block-${index}`}
                      />
                    ) 
                  case 'Image Text Content':
                    return (
                      <StaticPageHalfHalf 
                        data={contentBlock} 
                        key={`content-block-${index}`}
                        imageLeft={halfHalfCount % 2 == 1 ? false : true}
                      />
                    )
                }
              })}
            </>
          :''}
        </div>
        </Layout>
      </>
    )
  }
}


export const query = graphql`
  query($slug: String!) {
    site: datoCmsSite {
      ...siteData
    }
    nav: datoCmsNavigation {
      ...navData
    }
    footer: datoCmsFooter {
      ...footerData
    }
    pageData: datoCmsStaticPage(staticPageSlug: {eq: $slug}) {
      staticPageContent {
        ... on DatoCmsImageTextContent {
          model {
            name
          }
          text
          title
          imageContent {
            alt
            fluid(maxHeight: 500) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
        ... on DatoCmsFullWidthContent {
          model {
            name
          }
          fullWidthTextBlock
        }
      }
      staticPageSlug
      staticPageTitle
      staticPageDescription
    }
  }
`

export default connect(
  state => ({ 
    browser: state.browser,
  })
)(Staticpage);