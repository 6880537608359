import React from 'react'

import { parseHTML } from './../../helpers/parseHTML';

interface props {
  data: any
}

interface state {
}

export default class staticPageFullWidth extends React.Component<props, state> {
  
  constructor(props) {
    super(props);
    this.state = {
    };

  }

  componentDidMount = () => {
  }

  render () {
    let data = this.props.data;
    
    return (
      <div className={`static-page__full-width`}>
        {parseHTML(data.fullWidthTextBlock)}
      </div>
    )
  }
}
