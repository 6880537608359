import React from 'react'
import Img from 'gatsby-image/withIEPolyfill';

import { parseHTML } from '../../helpers/parseHTML';

interface props {
  data: any
  imageLeft: boolean
}

interface state {
}

export default class staticPageHalfHalf extends React.Component<props, state> {
  
  constructor(props) {
    super(props);
    this.state = {
    };

  }

  componentDidMount = () => {
  }

  render () {
    let data = this.props.data;
    
    return (
      <div className={`static-page__half-half ${this.props.imageLeft ? 'reverse':''}`}>
        {this.props.imageLeft ? 
          <>
            <div className="static-page__half-image">
              <Img fluid={data.imageContent.fluid} alt={data.imageContent.alt}/>
            </div>
            <div className="static-page__half-text">
              <h2 className={`title`}>{data.title}</h2>
              {parseHTML(data.text)}
            </div>
          </>
        :
          <>
            <div className="static-page__half-text">
              <h2 className={`title`}>{data.title}</h2>
              {parseHTML(data.text)}
            </div>
            <div className="static-page__half-image">
              <Img fluid={data.imageContent.fluid} alt={data.imageContent.alt}/>
            </div>
          </>
        }
      </div>
    )
  }
}
